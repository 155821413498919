import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import { disableOverlay, enableOverlay } from "../actions/ui.actions";
import { genericError } from "../actions/error.actions";
import { getAdminSettingsValueSuccess, GET_ADMIN_SETTINGS_VALUE, updateAdminSettingsValueSuccess, UPDATE_ADMIN_SETTINGS_VALUE } from "../actions/admin.settings.actions"
import { getAdminSettingsValue, updateAdminSettingsValue } from "../../services/admin.settings.services"

/**
 * getAdminSettingsValueEffect
 */
function* getAdminSettingsValueEffect({payload}) {
  try {
      const setting = yield call(getAdminSettingsValue, payload);
      yield put(getAdminSettingsValueSuccess(setting));
  } catch (err) {
      yield put(genericError(err));
      yield put(disableOverlay());
  }
}

/**
 * updateAdminSettingsValueEffect
 */
 function* updateAdminSettingsValueEffect({payload}) {
  try {
      yield put(enableOverlay('Updating admin settings...'));
      const setting = yield call(updateAdminSettingsValue, payload);
      if (setting) {
          yield put(updateAdminSettingsValueSuccess(setting));
          yield put(disableOverlay());
      }
  } catch (err) {
      console.log('error:', err);
      yield put(genericError(err));
      yield put(disableOverlay());
  }
}

export function* watchGetAdminSettingsValue() {
    yield takeEvery(GET_ADMIN_SETTINGS_VALUE, getAdminSettingsValueEffect);
}

export function* watchUpdateAdminSettingsValue() {
  yield takeEvery(UPDATE_ADMIN_SETTINGS_VALUE, updateAdminSettingsValueEffect);
}

function* adminSettingsSaga() {
    yield all([
        fork(watchGetAdminSettingsValue),
        fork(watchUpdateAdminSettingsValue),
    ]);
}

export default adminSettingsSaga;
