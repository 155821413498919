import {createAction} from "@reduxjs/toolkit";

// Errors action types
export const GENERIC_ERROR = '[error] Generic error';
export const API_ERROR = '[error] New api error';
export const CLEAN_ERROR = '[error] Clean error';

// Errors actions
export const genericError = createAction(GENERIC_ERROR);
export const apiError = createAction(API_ERROR);
export const cleanError = createAction(CLEAN_ERROR);
