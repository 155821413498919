import { GET_ADMIN_SETTINGS_VALUE_SUCCESS } from "../actions/admin.settings.actions";

const initialAdminSettingsState = {
    invoicesProcessingDay: null,
    invoicesMarginFeePercentage: null,
    invoicesMarginFeeLabel: null,
    invoicesAutomaticGeneration: null,
};

export const AdminSettingsReducer = (state = initialAdminSettingsState, action) => {
    switch (action.type) {
        case GET_ADMIN_SETTINGS_VALUE_SUCCESS:
            state[action.payload.key] = action.payload.value;
            return state;
        default:
            return state;
    }
}
