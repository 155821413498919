import {
    CHANGE_LAYOUT,
    CHANGE_LAYOUT_WIDTH,
    CHANGE_PRELOADER,
    CHANGE_SIDEBAR_THEME,
    CHANGE_SIDEBAR_TYPE,
    CHANGE_TOPBAR_THEME
} from "../actions/layout.actions";

const INIT_STATE = {
    layoutType: "vertical",
    layoutWidth: "fluid",
    leftSideBarTheme: "light",
    leftSideBarType: "icon",
    topbarTheme: "light",
    isPreloader: false,
    isMobile: false
};

export const LayoutReducer = (state = INIT_STATE, action) => {
    switch (action.type) {
        case CHANGE_LAYOUT:
            return {
                ...state,
                layoutType: action.payload
            };
        case CHANGE_PRELOADER:
            return {
                ...state,
                isPreloader: action.payload
            };

        case CHANGE_LAYOUT_WIDTH:
            return {
                ...state,
                layoutWidth: action.payload.width
            };
        case CHANGE_SIDEBAR_THEME:
            return {
                ...state,
                leftSideBarTheme: action.payload.theme
            };
        case CHANGE_SIDEBAR_TYPE:
            return {
                ...state,
                leftSideBarType: action.payload.sidebarType
            };
        case CHANGE_TOPBAR_THEME:
            return {
                ...state,
                topbarTheme: action.payload
            };
        default:
            return state;
    }
};
